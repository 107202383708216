import React, { Component, useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import InputMask from "react-input-mask";

function SimpleForm() {
    const [validated, setValidated] = useState(false);
    const [buttonClass, setButtonClass] = useState("btn-secondary"); // Defina a classe inicial
    const [buttonClass2, setButtonClass2] = useState("btn-secondary"); // Defina a classe inicial
    const [unidades, setUnidades] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [selectedUnidade, setSelectedUnidade] = useState("");
    const [selectedCurso, setSelectedCurso] = useState("");
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        // Fetch para obter as unidades do endpoint /api.php
        fetch("https://matricula.gizdigital.com.br/api.php")
            .then((response) => response.json())
            .then((data) => setUnidades(data))
            .catch((error) => console.error("Erro ao buscar unidades:", error));
    }, []);

    // console.log(unidades);

    const handleUnidadeChange = (event) => {
        const unidadeSelecionada = event.target.value;
        setSelectedUnidade(unidadeSelecionada);

        // Fetch para obter os cursos disponíveis para a unidade selecionada
        fetch(
            `https://matricula.gizdigital.com.br/api.php?unidade=${unidadeSelecionada}`
        )
            .then((response) => response.json())
            .then((data) => setCursos(data))
            .catch((error) => console.error("Erro ao buscar cursos:", error));
    };

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        setButtonClass("btn-success");
        // handleFile(fileUploaded);
    };

    const hiddenFileInput2 = useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick2 = (event) => {
        hiddenFileInput2.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange2 = (event) => {
        const fileUploaded = event.target.files[0];
        setButtonClass2("btn-success");
        // handleFile(fileUploaded);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (!form.reportValidity()) {
            const invalidFields = Array.from(form.elements).filter(
                (element) => !element.checkValidity()
            );

            if (invalidFields.length > 0) {
                // Focar no primeiro campo de entrada inválido
                invalidFields[0].focus();
            }

            // Impedir a propagação do evento se houver campos inválidos
            event.stopPropagation();
        } else {
            // Se o formulário for válido, prosseguir com a lógica de envio AJAX
            try {
                const formData = new FormData(form);
                // console.log([...formData.entries()]);

                Swal.fire({
                    title: "Carregando...",
                    allowOutsideClick: false, // Evita que o usuário feche o Swal clicando fora dele
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                // Substitua a URL abaixo pela sua URL de API
                const response = await fetch(
                    "https://matricula.gizdigital.com.br/api.php",
                    {
                        method: "POST",
                        body: formData,
                    }
                );

                if (response.ok) {
                    // Requisição bem-sucedida, faça algo com a resposta
                    // Requisição bem-sucedida, mostrar um Swal de sucesso
                    Swal.fire({
                        icon: "success",
                        title: "Parabéns!",
                        text: "Você deu o primeiro passo para o sucesso, em breve um responsável irá entrar em contato com você para finalizar sua matrícula.",
                    }).then(() => {
                        window.location.href = "https://gizdigital.com.br";
                    });
                } else {
                    // Trate erros na resposta
                    Swal.fire({
                        icon: "error",
                        title: "Erro!",
                        text: `A requisição falhou: ${response.statusText}`,
                    });
                }
            } catch (error) {
                // Trate erros na requisição
                console.error("Erro na requisição:", error);
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: "Ocorreu um erro na requisição.",
                });
            }
        }

        setValidated(true);
    };

    return (
        <Container className="shadow bg-white rounded p-3">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="form.Unidade">
                    <Form.Label>Unidade de Interesse</Form.Label>
                    <br />
                    <Form.Select
                        aria-label="Escolha sua unidade"
                        required
                        className="mb-3"
                        style={{ border: "none" }}
                        name="Unidade"
                        onChange={handleUnidadeChange}
                        value={selectedUnidade}
                    >
                        <option value="" disabled hidden>
                            Selecione uma unidade
                        </option>
                        {unidades.map((unidade, index) => (
                            <option key={index} value={unidade.id}>
                                {unidade.nome}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Por favor, escolha uma unidade.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="form.Curso">
                    <Form.Label>Curso de Interesse</Form.Label>
                    <br />
                    <Form.Select
                        aria-label="Escolha seu curso"
                        required
                        className="mb-3"
                        style={{ border: "none" }}
                        name="Curso"
                        onChange={(e) => setSelectedCurso(e.target.value)}
                        value={selectedCurso}
                    >
                        <option value="" disabled hidden>
                            Selecione um curso
                        </option>
                        {cursos.map((curso, index) => (
                            <option key={index} value={curso.nome}>
                                {curso.nome}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Por favor, escolha um curso.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Nome">
                    <Form.Label>Nome Completo</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder="Nome completo"
                        required
                        name="Nome"
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="email"
                        placeholder="email@exemplo.com"
                        required
                        name="Email"
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Por favor, insira um email válido.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Endereco">
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder="Rua exemplo, 999"
                        required
                        name="Endereco"
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Bairro">
                    <Form.Label>Bairro</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder=""
                        name="Bairro"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Complemento">
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder=""
                        name="Complemento"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Nascimento">
                    <Form.Label>Data de Nascimento</Form.Label>
                    <InputMask
                        className="mb-3 form-control"
                        mask="99/99/9999"
                        name="Nascimento"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.CPF">
                    <Form.Label>CPF</Form.Label>
                    <InputMask
                        className="mb-3 form-control"
                        mask="999.999.999-99"
                        placeholder="123.456.789-00"
                        name="CPF"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.RG">
                    <Form.Label>RG</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder=""
                        name="RG"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Naturalidade">
                    <Form.Label>Naturalidade</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        defaultValue="Brasil"
                        name="Naturalidade"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Pai">
                    <Form.Label>Nome do Pai</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder=""
                        name="Pai"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Mae">
                    <Form.Label>Nome do Mãe</Form.Label>
                    <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder=""
                        name="Mae"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Telefone">
                    <Form.Label>Telefone</Form.Label>
                    <InputMask
                        className="mb-3 form-control"
                        mask="(99) 99999-9999"
                        name="Telefone"
                        required
                    />
                    <Form.Control.Feedback>Tudo certo!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Documento">
                    <button
                        type="button"
                        className={`btn btn-secondary mb-3 btndocumento ${buttonClass}`}
                        onClick={handleClick}
                    >
                        Documento com Foto
                    </button>
                    <Form.Control
                        type="file"
                        className="mb-3"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        accept="image/*"
                        capture
                        style={{ display: "none" }}
                        name="ComprovanteDocumento"
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, envie uma foto do seu documento.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form.Residencial">
                    <button
                        type="button"
                        className={`btn btn-secondary mb-3 btndocumento ${buttonClass2}`}
                        onClick={handleClick2}
                    >
                        {" "}
                        Comprovante de Residencia
                    </button>
                    <Form.Control
                        type="file"
                        className="mb-3"
                        onChange={handleChange2}
                        ref={hiddenFileInput2}
                        accept="image/*"
                        capture
                        style={{ display: "none" }}
                        name="ComprovanteResidencial"
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, envie uma foto do seu comprovante de
                        residencia.
                    </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">Enviar Matrícula</Button>
            </Form>
        </Container>
    );
}

export default SimpleForm;
