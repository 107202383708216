import "bootstrap/dist/css/bootstrap.min.css";
import SimpleForm from "./components/SimpleForm";

function App() {
  return (
    <div className="app">
      <header className="bg-white text-white text-center py-3 shadow">
        <img
          src="/images/logo-giz-digital.png"
          alt="Logo"
          style={{ maxWidth: "100px" }}
        />
      </header>

      <div className="container mt-4">
        <div className="text-center mb-4">
          <h1>Faça sua matrícula</h1>
        </div>

        <SimpleForm />
      </div>
    </div>
  );
}

export default App;
